// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';
import { useQuickStart } from '@store/quickStart';

// i18n
import { useI18n } from 'vue-i18n';

// services
import ApiKeysService from '@services/apiKeysService';

// naive-ui
import { useMessage, useNotification } from 'naive-ui';

export default function (props, { emit }) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const bots = useBots();
    const quickStart = useQuickStart();

    // i18n
    const { t } = useI18n();

    // naive-ui
    const message = useMessage();
    const notification = useNotification();

    // vars
    const select = ref('');
    const apiKeyValue = ref(props.modelValue);
    const addApiLoading = ref(false);
    const apiOptions = ref(props.apiKeysRefs?.length ? 'select' : 'create');

    const errors = ref([]);

    watch(() => props.modelValue, v => {
        v === -1 && apiOptions.value === 'select'
            ? apiKeyValue.value = null
            : apiKeyValue.value = v;
    });

    watch(() => props.name, v => {
        if (apiOptions.value !== 'select')
            apiKeyModel.name.value = v;
    });

    watch(apiOptions, v => {
        if (v === 'select') {
            if (props.modelValue === -1)
                setTimeout(() => {
                    if (select.value)
                        apiKeyValue.value = null;
                }, 0);
        } else {
            apiKeyModel.name.value = props.name;
            emit('update:modelValue', -1);
        }  
    });

    const options = computed(() => {
        return [
            {
                value: 'select',
                disabled: !apiKeys.value.length,
                label: refs.localization.dialogs?.choose || t('assignmentTypes[0]'),
            }, {
                value: 'create',
                label: refs.localization.dialogs?.create || t('assignmentTypes[1]'),
            },
        ];
    });

    const apiKeys = computed(() => props.apiKeysRefs?.length ? props.apiKeysRefs.map(el => ({
        value: el.id,
        label: el.name,
        ...el,
    })) : []);

    watch(() => props.apiKeysRefs?.length, v => {
        console.log('kek');
        if (!v) {
            apiOptions.value = 'create';
        }
    });

    const buttonLabel = computed(() => {
        if (props.mode === 'quick-start') {
            return quickStart.localization['quick_start/api_key/create_new_button'];
        } else if (props.mode === 'bots') {
            return bots.localization['bots_new_apikey_add_button'];
        }

        return null;
    });

    const createApiDisable = computed(() => {
        let disable = false;

        for (let key in apiKeyModel) {
            if (!apiKeyModel[key].value) {
                disable = true;
                return disable;
            }
        }

        return disable;
    });

    const exchangeMetaInfo = computed(() => exchange(props.exchange.value)?.meta);
    const exchangeMeta2Info = computed(() => exchange(props.exchange.value)?.meta2);

    const nameContent = computed(() => {
        if (props.mode === 'quick-start') {
            return {
                title: quickStart.localization['quick_start/api_key_name/label'],
                placeholder: quickStart.localization['quick_start/api_key_name/placeholder'],
            };
        } else if (props.mode === 'bots') {
            return {
                title: bots.localization['bots_new_apikey_name_f'],
                placeholder: bots.localization['bots_new_apikey_name_i'],
            };
        }

        return null;
    });

    const keyContent = computed(() => {
        if (props.mode === 'quick-start') {
            return {
                title: quickStart.localization['quick_start/api_key_value/label'],
                placeholder: quickStart.localization['quick_start/api_key_value/placeholder'],
            };
        } else if (props.mode === 'bots') {
            return {
                title: bots.localization['bots_new_apikey_key_f'],
                placeholder: bots.localization['bots_new_apikey_key_i'],
            };
        }

        return null;
    });

    const signContent = computed(() => {
        if (props.mode === 'quick-start') {
            return {
                title: quickStart.localization['quick_start/api_key_sign/label'],
                placeholder: quickStart.localization['quick_start/api_key_sign/placeholder'],
            };
        } else if (props.mode === 'bots') {
            return {
                title: bots.localization['bots_new_apikey_sign_f'],
                placeholder: bots.localization['bots_new_apikey_sign_i'],
            };
        }

        return null;
    });

    const apiKeyModel = reactive({
        name: {
            value: props.name ? (props.name + '') : '',
            title: nameContent.value.title,
            placeholder: nameContent.value.placeholder,
            status: undefined,
            msg: undefined,
        },
        key: {
            value: '',
            title: keyContent.value.title,
            placeholder: keyContent.value.placeholder,
            status: undefined,
            msg: undefined,
        },
        sign: {
            value: '',
            title: signContent.value.title,
            placeholder: signContent.value.placeholder,
            status: undefined,
            msg: undefined,
        },
    });

    watch(() => apiKeyModel.name.value, () => {
        if (apiKeyModel.name.status === 'error') {
            apiKeyModel.name.status = undefined;
            apiKeyModel.name.msg = undefined;
        }
    });

    watch(() => apiKeyModel.key.value, () => {
        if (apiKeyModel.key.status === 'error') {
            apiKeyModel.key.status = undefined;
            apiKeyModel.key.msg = undefined;
        }
    });

    watch(() => apiKeyModel.sign.value, () => {
        if (apiKeyModel.sign.status === 'error') {
            apiKeyModel.sign.status = undefined;
            apiKeyModel.sign.msg = undefined;
        }
    });

    const exchange = (exchange) => {
        return refs.exchanges.find(el => el.id === exchange);
    };

    const onCreateApiKey = async () => {
        addApiLoading.value = true;

        errors.value.splice(0, errors.value.length);

        const records = {
            exchange: props.exchange.value,
            name: apiKeyModel.name.value,
            key: apiKeyModel.key.value,
            sign: apiKeyModel.sign.value,
            _formPath: 'apiKey',
        };

        const result = await ApiKeysService.addApiKeyToExchange(records.exchange, records);

        if (!result.data.status) {
            if (result.data?.errors_form) {
                const fields = result.data.errors_form.apiKey.fields;

                for (let key in apiKeyModel) {
                    const el = Object.keys(fields).find(el => el === key);

                    if (el) {
                        apiKeyModel[key].status = 'error';
                        apiKeyModel[key].msg = fields[el].msg;
                    } else {
                        apiKeyModel[key].status = 'success';
                        apiKeyModel[key].msg = undefined;
                    }
                }

                for (const key in fields) {
                    if (!Object.keys(apiKeyModel).includes(key)) {
                        errors.value.push(fields[key]);
                    }
                }
            }
        } else {
            // clean apiKeyModel
            for (let key in apiKeyModel)
                apiKeyModel[key].value = '';

            // show messages
            result.postMessages.forEach(el => {
                notification[el.success ? 'success' : 'error']({
                    content: el.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });

            // change api key
            emit('setApiKey', result.data.records[0]);

            // change active tab
            apiOptions.value = 'select';
        }

        addApiLoading.value = false;
    };

    const getNoKeysAvailableInfo = info => {
        return info.replace(/{{}}/gi, `<strong>${exchange(props.exchange.value)?.title}</strong>`);
    };

    onMounted(() => {
        if (props.modelValue === -1 && !gl.isMobile)
            apiKeyValue.value = null;
    });

    return {
        gl,
        bots,
        errors,
        select,
        options,
        apiKeys,
        quickStart,
        apiOptions,
        buttonLabel,
        apiKeyModel,
        apiKeyValue,
        addApiLoading,
        createApiDisable,
        exchangeMetaInfo,
        exchangeMeta2Info,
        t,
        onCreateApiKey,
        getNoKeysAvailableInfo,
    };
}